import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Container } from "@util/standard";
import { Query } from "@graphql-types";
import { BlocksContent, Button } from "@global";
import Layout from "@shared/layout";
import { TABLET_BREAKPOINT } from "@util/constants";

const TextWrapper = styled(Container)`
  h1 {
    font-size: 80px;
    margin: 0px !important;
  }
  h2 {
    margin: 20px 0 0 0 !important;
  }
  margin-bottom: 55px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 25px;
    }
    margin-bottom: 40px;
  }
`;

const NotFoundPage = () => {
  const { allSanityPageNotFound }: Query = useStaticQuery(graphql`
    query pageNotFoundQuery {
      allSanityPageNotFound {
        nodes {
          region {
            countryCode
          }
          ctas {
            ...sanityLink
          }
          pageText {
            ...sanityBlockContent
          }
        }
      }
    }
  `);

  if (allSanityPageNotFound == null) return null;

  const filteredContent = allSanityPageNotFound.nodes.filter(data => {
    if (data == null || data.region == null) return null;
    return data.region.countryCode === process.env.GATSBY_REGION;
  });

  const pageNotFoundContent = filteredContent[0];

  return (
    <Layout hideFooterCtas>
      <Container
        flexDirection="column"
        width="70%"
        margin="304px auto"
        tabletWidth="90%"
        tabletMargin="170px auto"
      >
        {pageNotFoundContent.pageText && (
          <TextWrapper>
            <BlocksContent data={pageNotFoundContent.pageText} />
          </TextWrapper>
        )}
        {pageNotFoundContent.ctas && (
          <Container>
            {pageNotFoundContent.ctas.map((cta, index) => {
              if (cta == null) return null;
              return <Button {...cta} theme={index > 0 ? "text" : "base"} key={cta._key} />;
            })}
          </Container>
        )}
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
